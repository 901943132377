import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonVnt from "../components/button-vnt"

const Database = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Data-report-pana.png" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Base de datos" />
      <div className="container page-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt={data.file.base}
              />
            </div>
          </div>

          <div className="col-lg-6 col-xl-5 ml-xl-auto">
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Database.</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                En vangTry estamos convencidos de que uno de los activos más
                valiosos con el que puedes contar es la información de tu
                negocio.
              </p>
              <h2 className="title-text">¿Cómo lo hacemos?.</h2>
              <p className="paragpah-text">
                Nosotros lo apoyamos mediante la administración de bases de
                datos empleando las mejores practicas de los fabricantes
                mejorando sus capacidades mediante el desarrollo (p.ej.,
                PL/SQL), migraciones de datos, afinamiento de base de datos
                (mejoras en el desempeño de consultas y procesos).
              </p>
            </div>
            <div className="mt-4">
              <ButtonVnt
                linkTo="/#contact-us"
                textLabel="Contáctanos"
                buttonType="default"
              ></ButtonVnt>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Database
